import React, { useContext, useState, useRef, useEffect, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { Button, CircularProgress, MenuItem, Select, Typography } from '@mui/material';
import { makeStyles } from '@mui/material/styles';
import queryString from 'query-string';
import moment from 'moment-timezone'
// import seasiLogo from './resources/seasi-logo.jpg'
import seasiLogo from './resources/seasi-logo.png'
import pfmLogo from './resources/pfm-logo.png'
import vvtvLogo from './resources/vv-logo.png'
import ewLogo from './resources/ew-logo.png'
import background from './resources/black-bg.jpg'
import LoadingPage from './LoadingPage';
import config from './config.json'
import ThemeContext from './ThemeContext';
import { useTranslation } from 'react-i18next';

const PREFIX = 'TechCheckScheduler';

const classes = {
    fullscreen: `${PREFIX}-fullscreen`,
    root: `${PREFIX}-root`,
    headerLogo: `${PREFIX}-headerLogo`,
    card: `${PREFIX}-card`,
    fixedHeader: `${PREFIX}-fixedHeader`,
    fixedFooter: `${PREFIX}-fixedFooter`,
    white: `${PREFIX}-white`,
    titleBox: `${PREFIX}-titleBox`,
    content: `${PREFIX}-content`,
    container: `${PREFIX}-container`,
    spacing: `${PREFIX}-spacing`,
    select: `${PREFIX}-select`,
    loading: `${PREFIX}-loading`,
    blank: `${PREFIX}-blank`,
    submit: `${PREFIX}-submit`,
    buttonProgress: `${PREFIX}-buttonProgress`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
    [`& .${classes.fullscreen}`]: {
        width: '100vw',
        height: '100vh',
        overflow: 'hidden'
    },
    [`& .${classes.root}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        // background: '#222'
        background: `url(${background})`,
        backgroundSize: 'cover'
    },
    [`& .${classes.headerLogo}`]: {
        height: '100%',
        maxHeight: '80px'
    },
    [`& .${classes.card}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1rem 2rem 2rem 2rem',
        textAlign: 'center',
        width: '55%',
        minWidth: '300px',
        maxWidth: '800px'
    },
    [`& .${classes.fixedHeader}`]: {
        position: 'static',
        top: 0,
        width: '100%',
        height: '5rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        padding: '1rem',
        // padding: '1em 2em 1em 2em',
        boxSizing: 'border-box',
        background: '#0f161c',
        // boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
    },
    [`& .${classes.fixedFooter}`]: {
        position: 'static',
        bottom: 0,
        width: '100%',
        // height: '5rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        padding: '1rem',
        // padding: '1em 2em 1em 2em',
        boxSizing: 'border-box',
        background: '#0f161c',
        // boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
    },
    [`& .${classes.white}`]: {
        color: 'white'
    },
    [`& .${classes.titleBox}`]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2rem'
    },
    [`& .${classes.content}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // padding: '1rem 2rem 2rem 2rem',
        textAlign: 'center',
        height: '100%',
        width: '95%',
        // minWidth: '300px',
        // maxWidth: '800px',
        color: 'white',
        position: 'relative'
    },
    [`& .${classes.container}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // padding: '1rem 2rem 2rem 2rem',
        textAlign: 'center',
        // marginTop: 'auto',
        minHeight: '68%',
        height: 'auto',
        marginBottom: 'auto'
        // width: '55%',
        // minWidth: '400px',
        // maxWidth: '800px',
        // minHeight: '300px'
    },
    [`& .${classes.spacing}`]: {
        width: '100%',
        marginTop: '2rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    [`& .${classes.select}`]: {
        margin: '0.25rem 0 0.25rem 0',
        backgroundColor: 'white'
    },
    [`& .${classes.loading}`]: {
        margin: '0.25rem 0 0.25rem 0',
        // width: 'calc(37px + 1.1876em)',
        // height: 'calc(37px + 1.1876em)'
    },
    [`& .${classes.blank}`]: {
        marginTop: 'calc(37px + 1.1876em)'
    },
    [`& .${classes.submit}`]: {
        position: 'relative',
        alignSelf: 'flex-end',
        marginTop: '1rem'
    },
    [`& .${classes.buttonProgress}`]: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});

const timezone = moment.tz.guess()

const TechCheckScheduler = React.memo(({ qualtricsPreview, techCheckDateTime, getAcuityCalendarDates, getAcuityCalendarDateTimes, scheduleTechCheck }) => {
    const [submitted, setSubmitted] = useState(false)
    const [error, setError] = useState(false)
    const [techCheckDates, setTechCheckDates] = useState()
    const [techCheckDateTimes, setTechCheckDateTimes] = useState({})
    const [selectedDate, setSelectedDate] = useState("")
    const [selectedTime, setSelectedTime] = useState("")
    const [scheduledDateTime, setScheduledDateTime] = useState(techCheckDateTime)
    const selectDateRef = useRef(null);
    const selectTimeRef = useRef(null);

    const {t} = useTranslation('tech_check_scheduler');

    useEffect(() => {
        if (getAcuityCalendarDates) {
            getAcuityCalendarDates(timezone).then((dates) => {
                console.log(dates)
                setTechCheckDates(dates)
            })
        }
    }, [getAcuityCalendarDates])

    useEffect(() => {
        if (selectedDate && getAcuityCalendarDateTimes) {
            getAcuityCalendarDateTimes(selectedDate, timezone).then((times) => {
                console.log(times)
                setTechCheckDateTimes(prev => ({ ...prev, [selectedDate]: times }))
            })
        }
    }, [selectedDate, getAcuityCalendarDateTimes])

    const handleSelectDate = useCallback((event) => {
        selectDateRef.current.node.blur();
        // event.currentTarget.blur()
        setSelectedDate(event.target.value)
        setSelectedTime("")
    }, [])

    const handleSelectTime = useCallback((event) => {
        selectTimeRef.current.node.blur();
        // event.currentTarget.blur()
        setSelectedTime(event.target.value)
    }, [])

    const handleSubmit = useCallback(async (event) => {
        if (selectedTime) {
            setSubmitted(true)
            setError(false)
            try {
                const data = await scheduleTechCheck(selectedTime)
                console.log(data)
                setScheduledDateTime(data.scheduledTime)
            } catch (err) {
                console.log(err)
                setSubmitted(false)
                setError(true)
            }
        }
    }, [selectedTime, scheduleTechCheck])

    return scheduledDateTime ? <Root>
        <div className={classes.titleBox}>
            <Typography variant="h4">{t('title')}</Typography>
            <br />
            <Typography variant="h6">{t('tech_check_scheduled', {time: moment(scheduledDateTime).format('dddd, MMMM Do YYYY [at] h:mm a')})}</Typography>
        </div>
    </Root> : <Root>
        <Typography variant="h4">{qualtricsPreview ? t('qualtrics_registration_successful') : t('thanks_for_registering')}</Typography>
        <br />
        <Typography variant="h6">{qualtricsPreview ? t('preview_available_check_times') : t('please_schedule_tech_check')}</Typography>
        <div className={classes.spacing}>
            {techCheckDates ? techCheckDates.length ? <Select
                inputRef={selectDateRef}
                value={selectedDate}
                variant="outlined"
                onChange={handleSelectDate}
                className={classes.select}
                displayEmpty
            >
                <MenuItem value="" disabled>
                    {t('available_dates')}
                </MenuItem>
                {techCheckDates.map(({ date }) => <MenuItem key={`TC.${date}`} value={date}>{moment.tz(date, timezone).format('dddd, MMMM Do YYYY')}</MenuItem>)}
            </Select> : <Typography variant="body1">{t('tech_checks_taken')}<br />{t('dont_worry_well_email_you')}</Typography> : <div className={classes.loading}><CircularProgress size={'calc(37px + 1.1876em)'} /></div>}
            {selectedDate ? (techCheckDateTimes[selectedDate] ? <Select
                inputRef={selectTimeRef}
                value={selectedTime}
                variant="outlined"
                onChange={handleSelectTime}
                className={classes.select}
                displayEmpty
            >
                <MenuItem value="" disabled>
                    {t('available_times')}
                </MenuItem>
                {techCheckDateTimes[selectedDate].map(({ time }) => <MenuItem key={`TC.${selectedDate}.${time}`} value={time}>{moment.tz(time, timezone).format('h:mm a')}</MenuItem>)}
            </Select> : <div className={classes.loading}>
                <CircularProgress size={'calc(37px + 1.1876em)'} />
            </div>
            ) : <div className={classes.blank} />}
            {<div className={classes.submit}>
                <Button color='primary' variant='contained' disabled={qualtricsPreview || submitted || !selectedTime} hidden={!selectedDate} onClick={handleSubmit}>{t('submit')}</Button>
                {submitted && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>}
        </div>
        <Typography variant="subtitle2" color="error" style={{ marginTop: '1rem', visibility: error ? 'visible' : 'hidden' }}>{t('error_scheduling_tech_check')}</Typography>
    </Root>;
})

export default TechCheckScheduler;
